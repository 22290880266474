<template>
  <div class="login-page">
    <transition name="fade">
      <div class="wallpaper-login"></div>
    </transition>
    <!-- <div class="wallpaper-register"></div> -->

    <v-container>
      <ValidationObserver
        ref="observer"
        v-slot="{ valid }"
      >
        <v-row>
          <v-col
            lg="4"
            md="6"
            sm="8"
            class="mx-auto"
          >
            <v-card class="card login">
              <!-- <h1>Sign In</h1> -->
              <v-card-title>{{ title }}</v-card-title>
              <v-form>

                <v-col cols="12">
                  <render-input
                    :input="fieldConfig.userName"
                    v-model="credential.userName"
                    @keyup.enter="submit()"
                  ></render-input>
                </v-col>

                <v-col cols="12">
                  <render-input
                    :input="fieldConfig.password"
                    v-model="credential.password"
                    @keyup.enter="submit()"
                  ></render-input>
                </v-col>

              </v-form>
              <v-alert
                v-if="showError"
                type="error"
                class="mx-1"
              >
                Invalid credential provided. Please verify your information and try
                again.
              </v-alert>
              <v-card-actions v-if="!isProductionEnvironment">
                <!-- <v-btn class="btn-text" text @click="openForgotDialog"
            >Forgot Passoword?</v-btn
          > -->
              </v-card-actions>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="primary"
                  @click="submit()"
                  :disabled="!valid"
                >Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-container>
  </div>
</template>

<script>
import {
  logIn,
  logOut,
  getToken,
  setStorageToken,
} from "./../../js/authorization";
import { InputType } from "./../../js/constants";
import customDialog from "./../../components/custom/custom-dialog";
import { mapActions } from "vuex";
import { mockUpAdminUser } from "./../../_specific/exportJs";

export default {
  components: {
    customDialog,
  },
  data() {
    return {
      credential: {
        userName: "",
        password: "",
      },
      showError: false,
      forgotData: null,
      roles: [],
      selectedRole: null,
      isTestMode: false,
    };
  },
  created() {
    this.hideLoading();
    logOut();
    if (this.isProductionEnvironment) {
      this.isTestMode = false;
    }
  },
  methods: {
    ...mapActions("loading", ["showLoading", "hideLoading"]),

    submit() {
      //fixme: mockup userName & password access to test mode
      let mockup = mockUpAdminUser(this.credential.userName);
      if (mockup.valid) {
        this.credential = mockup.credential;
        return;
      }

      this.showError = false;
      this.$refs.observer.validate().then((valid) => {
        if (!valid) {
          return;
        }
        this.showLoading();

        let payload = _.cloneDeep(this.credential);

        logIn(payload)
          .then((res) => {
            this.$router.push("/");
          })
          .catch((er) => {
            this.showError = true;
          })
          .finally(() => {
            this.hideLoading();
            if (this.isTestMode) {
              this.mockUpData(); //fixme: for test
              this.$router.push("/");
            }
          });
      });
    },
    openForgotDialog() {
      this.$refs.forgotDialog.hideLoading();
      this.$refs.forgotDialog.showDialog();
    },
    submitForgotPassword() {
      this.showAlertSuccess(
        "Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder."
      );
      this.$refs.forgotDialog.hideDialog();
    },
    mockUpData() {
      //fixme : remove forward
      let data = getToken();
      data.displayName = this.credential.userName;
      data.role = this.selectedRole.id;

      //fixme : remove forward
      // if (!data.expiration) {
      //   var now = new Date();
      //   now.setDate(now.getDate() + 1);
      //   data.expiration = now.toJSON();
      // }

      setStorageToken(JSON.stringify(data));
    },
  },

  computed: {
    title() {
      return `${process.env.VUE_APP_NAME} Login`;
    },
    fieldConfig() {
      return {
        userName: {
          name: "userName",
          text: "Username",
          type: InputType.text,
          icon: "mdi-account",
          rule: {
            required: true,
          },
        },
        password: {
          name: "password",
          text: "Password",
          type: InputType.password,
          icon: "mdi-lock",
          rule: {
            required: true,
          },
        },
        role: {
          name: "role",
          text: "Assume Role",
          type: InputType.dropdown,
          rule: {
            required: true,
          },
          data: {
            promise: getRoles(),
          },
        },
        testMode: {
          name: "testMode",
          text: "Test mode",
          type: InputType.checkBox,
        },
        forgotData: {
          name: "email",
          text: "Please Enter your email",
          type: InputType.text,
          rule: {
            required: true,
            email: true,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss">
// https://codepen.io/rpandrews/pen/XWbjJEg
.login-page {
  align-items: center;
  display: flex;
  height: 100vh;

  // .wallpaper-login {
  //   background: url(https://images.pexels.com/photos/32237/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
  //     no-repeat center center;
  //   background-size: cover;
  //   height: 100%;
  //   position: absolute;
  //   width: 100%;
  // }

  .wallpaper-login {
    background: url("/img/alex-sanislav-YoUuoIsvgHY-unsplash-min (2).jpg")
      no-repeat center center;
    background-size: cover;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>

